.line-row {
  display: flex;
  align-items: center;
}

.line {
  width: 60px;
  height: 2px;
  background-color: black;
  margin: 0 5px;
}

/* styles.css (or any other CSS file) */

/* styles.css (or any other CSS file) */

.loader-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
  margin-bottom: 20px;
}

.loader-message {
  font-size: 18px;
  color: #333;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.Neutral {
  background-color: #a7a7a7 !important;
  cursor: pointer;
  color: white;
}

.Priority {
  background-color: #5bbb60 !important;
  cursor: pointer;
  color: white;
}

.Avoid {
  background-color: #ff536b !important;
  cursor: pointer;
  color: white;
}

div.card {
  background-color: white;
  border-radius: 0px;
}

.bg-aida-table-header,
.bg-aida {
  background-color: #f5f5ef;
}

thead.bg-aida-table-header > tr {
  border-top: 2px solid black;
}

thead.bg-aida-table-header + tbody > :last-child {
  border-bottom: 2px solid black;
}

thead.bg-aida-table-header.exclude-last-row + tbody > :last-child {
  border-bottom: 0px solid var(--falcon-table-border-color);
}

body,
.alert,
.badge,
.blockquote-footer,
.btn,
.invalid-feedback,
.navbar,
.pagination,
.tox .tox-dialog__footer .tox-button--secondary,
.tox .tox-dialog__footer .tox-button:last-child,
.tox .tox-menu__footer .tox-button--secondary,
.tox .tox-menu__footer .tox-button:last-child,
.valid-feedback,
button,
input,
label,
optgroup,
select,
textarea {
  font-family: 'IBM Plex Sans' !important;
}

.font-ibm-plex-sans {
  font-family: 'IBM Plex Sans' !important;
}

.font-ibm-plex-serif {
  font-family: 'IBM Plex Serif' !important;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'IBM Plex Sans' !important;
}

button.btn-outline-magenta {
  color: #ff2d86;
  border-color: #ff2d86;
}

button.btn-magenta,
button.btn-outline-magenta:hover {
  color: #fff;
  background-color: #ff2d86;
  border-color: #ff2d86;
  box-shadow: inset 0 1px 0 hsla(0, 0%, 100%, 0.15),
    0 1px 1px rgba(0, 0, 0, 0.075);
}

.bg-soft-active-background {
  background-color: #d4d5d0 !important;
}

div.aida-border-dashed {
  border: dashed #d4d5d0 !important;
}

.bg-soft-primary {
  background-color: #dcdafe !important;
}

.bg-pink {
  background-color: #e67ce1 !important;
}

.form-select:disabled,
.form-control:disabled {
  background-color: #f2f2f2 !important;
}

.text-plan {
  color: #c3c3c3 !important;
}
